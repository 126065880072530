import React from "react";
import s from "./style.module.css"

const Logo =()=>{
    return(
        <div className={s.logo}>
            <span className={s.red}>кох</span>авто.рф
        </div>
    )
}
export default Logo