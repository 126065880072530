import React from "react";
import s from "./style.module.css"
import one from "../../media/1.jpg"
import two from "../../media/2.jpg"
import three from "../../media/3.jpg"

const Info = ()=>{
    return(
        <div  style={{background:"black"}}>
            <h1 style={{color:"white"}}>О <span className={s.anim} style={{color:"red"}}>Кох</span>авто</h1>
            <div className={s.info}>
                <div className={s.text}>
                    <p>
                        Наша компания появилась на заре возникновения детейлинг-культуры в России и вместе со всей индустрией проделала огромный путь в своем развитии. Сегодня, имея большой опыт за плечами, мы точно знаем, какой комплекс работ нужен в каждом конкретном случае.<br/><br/>
                        Мы предлагаем нашим клиентам решения, находя золотую середину между их желаниями, возможностями и реальными потребностями.<br/><br/>
                        Все виды услуг в сфере детейлинга автомобиля, начиная от полировки и химчистки и заканчивая покраской сколов и нанесением различных защитных покрытий, мы выполняем качественно и профессионально.
                    </p>
                </div>
                <div className={s.img}>
                    <img src={one} alt=""/>
                    <img src={two} alt=""/>
                    <img src={three} alt=""/>
                </div>
            </div>

        </div>
    )
}
export default Info