import React from "react";
import {Map, Placemark, YMaps} from "react-yandex-maps"


const YMap=()=>{
    const mapData = {
        center: [55.668346, 37.471488],
        zoom: 10,
    }
    const coordinates = [
        [55.668346, 37.471488],
        [55.609441, 37.624409],
        [55.588511, 37.648340]
    ]
    return(
        <YMaps>
            <Map width={"100%"} defaultState={mapData}>
                {coordinates.map(coordinate => <Placemark geometry={coordinate} />)}
            </Map>
        </YMaps>
    )
}
export default YMap