import React from "react";
import leg from "../../media/leg.png"
import kro from "../../media/kro.png"
import s from "./style.module.css"

const Price = () =>{

    return(
        <div style={{background:"white"}}>
            <h1>Наши услуги</h1>
            <div>
                <h2><span className={s.anim} style={{color:"red"}}>Хим</span>чистка</h2>
                <div className={s.price}>
                    <div className={s.priceinner}>
                        <b>Химчистка автомобиля эконом-класса:</b>
                        полную химчистку автомобиля эконом-класса 7000 руб.
                        полную премиум-химчистку автомобиля эконом-класса 7500 руб.<br/><br/>

                        <b>Химчистка автомобиля среднего класса:</b>
                        полную химчистку автомобиля среднего класса 9900 руб.
                        полную премиум-химчистку автомобиля среднего класса  8100 руб.<br/><br/>

                        <b>Химчистка автомобиля бизнес-класса:</b>
                        полную химчистку автомобиля бизнес-класса 7900 руб.
                        полную премиум-химчистку автомобиля бизнес-класса 8400 руб.<br/>
                    </div>
                </div>

            </div>
            <div className={s.price__item}>
                <h2><span className={s.anim} style={{color:"red"}}>Пол</span>ировка</h2>
                <div className={s.price__inner}>
                    <b>Абразивная полировка автомобиля:</b><br/>
                    абразивную полировку автомобиля с удалением царапин и нанесением защитного покрытия 10 500 руб.
                </div>
                <div className={s.price__inner}>
                    <b>Шиомонтаж:</b><br/>
                    Доплата за кроссовер 500р

                </div>
                <table border={1} cellSpacing={1} cellPadding={1}>
                    <tbody>
                    <tr bgcolor="silver">
                        <td>Радиус колеса/Услуга</td>
                        <td style={{ textAlign: "center" }}>&nbsp;R13-R15</td>
                        <td style={{ textAlign: "center" }}>&nbsp;R16</td>
                        <td style={{ textAlign: "center" }}>&nbsp;R17</td>
                        <td style={{ textAlign: "center" }}>R18</td>
                        <td style={{ textAlign: "center" }}>&nbsp;R19</td>
                        <td style={{ textAlign: "center" }}>&nbsp;R20</td>
                        <td style={{ textAlign: "center" }}>R21</td>
                        <td style={{ textAlign: "center" }}>R22</td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;<b>Комплекс (снятие/установка, </b>
                            <br />
                            <b>
                                <br />
                                разборка/сборка, балансировка 4-х колес){" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />3000
                            </b>
                            <br />
                            <b> </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />3200{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />3400{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />3800{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />4200{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />5400{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />6600{" "}
                            </b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <b>
                                <br />6800
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;Снятие/установка 1-го колеса</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>250</td>
                        <td style={{ textAlign: "center" }}>300</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Разборка/сборка 1-го колеса</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>250</td>
                        <td style={{ textAlign: "center" }}>250</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                    </tr>
                    <tr>
                        <td>
                            <div>&nbsp;Балансировка 1-го колеса</div>
                        </td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>250</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>350</td>
                        <td style={{ textAlign: "center" }}>400</td>
                        <td style={{ textAlign: "center" }}>400</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Установка вентиля (вентиль + работа)</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                            <a href="#">
                                Установка датчика давления TPMS
                            </a>
                        </td>
                        <td>&nbsp;100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                        <td>100-250</td>
                    </tr>
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td>&nbsp;Ремонт прокола колеса (жгут, без снятия/установки)</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                        <td style={{ textAlign: "center" }}>300</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Ремонт прокола установкой заплатки</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                        <td style={{ textAlign: "center" }}>600</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Проверка давления или подкачка колеса</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                        <td style={{ textAlign: "center" }}>25</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Хранение одной шины/диска в день</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                        <td style={{ textAlign: "center" }}>50</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Зачистка диска, уплотнение борта одной шины герметиком</td>
                        <td style={{ textAlign: "center" }}>&nbsp;100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>150</td>
                    </tr>
                    <tr>
                        <td>&nbsp;Утилизация шины/диска</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>100</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>150</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                        <td style={{ textAlign: "center" }}>200</td>
                    </tr>
                    </tbody>
                </table>

            </div>

            </div>
    )
}
export default Price