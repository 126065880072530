import React from "react";
import Logo from "../Logo/Logo";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import s from "./style.module.css"
import one from "../../media/detailing.jpeg"


const Slider =()=>{
    const slider=()=>{

    }
    return(
           <div className={s.slider}>
                   <div className={s.button}>
                       <div className={s.one}><img src={one} alt=""/></div>

                   </div>
               <div className={s.onetext}>
                   <div className={s.logo}><Logo/></div>
                   <h1>ДЕТЕЙЛИНГ ЦЕНТР</h1>
                   <p>Индивидуальный подход к каждому клиенту и гарантия на все работы</p>
               </div>
           </div>
    )
}
export default Slider