import React from 'react';
import './App.css';
import Slider from "./Components/Slider/Slider";
import Info from "./Components/Info/Info";
import Adress from "./Components/Adress/Adress";
import YMap from "./Components/Map/Map";
import Price from "./Components/Price/Price";

function App() {
    return (
        <div className="App">
            <Slider/>
            <Info/>
            <Price/>
            <Adress/>
            <YMap/>
        </div>
    );
}

export default App;
