import React from "react";
import s from "./style.module.css"
import tel from "../../media/tel.svg"
import addr from "../../media/adress.svg"

const Adress =()=>{
    return(
        <div className={s.adress}>
            <h1><span className={s.anim} style={{color:"red"}}>Кон</span>такты</h1>
            <p>Для записи на консультацию и по любым <br/>вопросам звоните нам по телефонам </p>
           <h3><a href="tel:+79693434888">+79693434888</a><br/></h3>
           <h3><a href="tel:+79153478166">+79153478166</a><br/></h3>
            <div className={s.icon}>
                <div className={s.icon_inner}><img src={tel} alt=""/><br/><br/><a href="tel:+79693434888">+79693434888</a></div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,улица Академика Анохина 6к2</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,Ильменский проезд 8 стр 3</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,Дмитровское шоссе 56 корпус 1 стр 2</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,Проспект Вердандского 89</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,улица Озерная 45</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,улица Беломорская 40 стр2</div>
                <div className={s.icon_inner}><img src={addr} alt=""/> <br/><br/>Москва,улица Михалковская 7</div>
            </div>
        </div>
    )
}
export default Adress